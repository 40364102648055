body {
  color: #fff;
  background: url("singularity.ac65cde2.jpg") center / cover no-repeat fixed;
}

.App {
  text-align: center;
  width: 100%;
  max-height: 500px;
  font-family: Courier New, monospace;
  position: absolute;
  top: 22%;
}

@media only screen and (width <= 600px) {
  .App {
    top: 11%;
  }
}

h1 {
  font-size: 42px;
  font-weight: normal;
}

.passwords {
  margin-bottom: 2em;
  padding: 0;
  font-size: 32px;
  list-style: none;
}

.more {
  background-color: #fff;
  border: 2px solid #000;
  padding: 10px 25px;
  font-family: Courier New, monospace;
  font-size: 32px;
}

.more:hover {
  background-color: #fdd836;
}

.more:active {
  background-color: #ffefa9;
}

section#page {
  background: #eee;
  min-width: 25em;
  max-width: 60em;
  margin: 0 auto;
  padding: 20px;
}

textarea#gpgTextBox {
  background: #ffffffbf;
  width: 90%;
  min-width: 300px;
  max-width: 1200px;
  height: 300px;
  max-height: 800px;
}

section#shoutbox {
  color: #00008b;
  background: #d9defa;
  width: 21em;
  margin: 50px auto 0;
  padding: 20px;
}

div#logoBox {
  z-index: -1;
  background-color: #0000;
  height: 40px;
  position: relative;
  top: -520px;
  left: 320px;
}
/*# sourceMappingURL=index.c4adb886.css.map */
