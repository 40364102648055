body {
    background: url('../public/singularity.jpg') center fixed no-repeat;
    background-size: cover;
    color: white;
}

.App {
    position: absolute;
    max-height: 500px;
    top: 22%;

    text-align: center;
    font-family: "Courier New", monospace;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .App { top: 11%; }
}

h1 {
    font-weight: normal;
    font-size: 42px;
}

.passwords {
    list-style: none;
    padding: 0;
    font-size: 32px;
    margin-bottom: 2em;
}

.more {
    font-size: 32px;
    font-family: "Courier New", monospace;
    border: 2px solid #000;
    background-color: #fff;
    padding: 10px 25px;
}
.more:hover {
    background-color: #FDD836;
}
.more:active {
    background-color: #FFEFA9;
}

section#page {
    background: #EEEEEE;
    min-width: 25em;
    max-width: 60em;
    margin: 0 auto;
    padding: 20px;
}

textarea#gpgTextBox {
    height: 300px;
    width: 90%;
    min-width: 300px;
    max-width: 1200px;
    max-height: 800px;
    background: rgba(255, 255, 255, 0.75);
}

section#shoutbox {
    background: #d9defa;
    color: darkblue;
    padding: 20px;
    margin: 0 auto;
    margin-top: 50px;
    width: 21em;
}

div#logoBox {
    position: relative;
    top: -520px;
    height: 40px;
    left: 320px;
    background-color: transparent;
    z-index: -1;
}
